#title {
  margin-top: 50px;
  font-size: 4rem;
}

#subtitle {
  font-size: 2.5rem;
}

#title,
#subtitle {
  text-align: center;
}

#title span {
  color: #ca2121;
}

#title a,
#subtitle a {
  text-decoration: none;
}

@media screen and (max-width: 960px) {
  #title {
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  #title {
    margin-top: 1rem;
    font-size: 3rem;
  }
  #subtitle {
    font-size: 2rem;
  }
}
