.navbar {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70%;
  justify-content: space-between;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #ca2121;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.active {
  background-color: #ca2121;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
  font-size: 3rem;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar {
    justify-content: flex-start;
    height: 75px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 75px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background-color: rgba(24, 23, 23, 0.95);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 3rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #ca2121;
    border-radius: 0;
  }

  .menu-icon {
    display: flex;
    font-size: 3rem;
    cursor: pointer;
    margin-left: 1rem;
  }

  .fa-times {
    color: #fff;
    font-size: 4rem;
  }
}
