#footer {
  width: 100%;
  background-color: rgb(71, 71, 71);
  min-height: 7vh;
  display: flex;
  align-items: center;
  border-top: 2px solid #ca2121;
}

#footer-wrapper {
  margin: 0 auto;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer-nav {
  padding: 15px 0 10px;
}

#footer-nav ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
}

#footer-nav ul > li > a {
  margin: 0 10px;
  text-decoration: none;
}

#footer-nav ul > li > a:hover {
  text-decoration: underline;
}

#footer-nav ul > li > a:active {
  text-decoration: underline !important;
}

#footer-contact {
  padding: 10px 0;
}

#footer-contact ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

#footer-contact ul > li {
  margin: 0 10px;
}

.footer-contact-icon {
  font-size: 1.5rem;
}

@media screen and (max-width: 960px) {
  #footer-wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  #footer-wrapper {
    flex-direction: column;
  }
  #footer-nav ul {
    flex-direction: column;
  }

  #footer-nav ul > li {
    margin: 10px 0;
  }
}
