* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

body {
  background-color: rgb(24, 23, 23);
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
  margin-bottom: 10px;
}

p {
  font-size: 0.95rem;
}

hr {
  border: 1px solid #ca2121;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(71, 71, 71);
  margin: 0 auto 50px auto;
  padding: 3rem;
  max-width: 70%;
  letter-spacing: 0.1rem;
  line-height: 1.75rem;
  border-radius: 5px;
  text-shadow: 1px 1px black;
}

.section-header {
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: center;
}

.resume {
  text-align: center;
}

.resume-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.resume-section {
  text-align: left;
  margin: 20px;
}

.resume-aside {
  text-align: left;
  margin: 20px;
}

.resume-list,
.aside-list,
#reference-list {
  margin: 20px 0;
  font-size: 0.95rem;
  list-style-position: outside;
}

.resume-list li {
  margin-left: 1rem;
}

.aside-list li,
#reference-list li {
  list-style: none;
}

.pdf-link {
  padding: 10px;
}

.pdf-link:hover {
  background-color: rgb(37, 37, 37);
  border-radius: 5px;
}

#projects {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.contact {
  text-align: center;
  width: 100%;
}

#contact-form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 500px;
}

.form-control {
  display: block;
  width: 100%;
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
  color: rgb(24, 23, 23);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s;
  margin: 0 auto 1.25rem auto;
  padding: 0.25rem;
  font-size: 1rem;
}

.form-control:focus {
  border-color: #ca2121;
  outline: 0;
}

textarea {
  min-height: 120px;
  width: 100%;
  resize: vertical;
}

.submit-button {
  font-size: 1.125rem;
  display: block;
  width: 100%;
  padding: 0.75rem;
  background: #ca2121;
  color: #fff;
  border-radius: 0.25rem;
  cursor: pointer;
  outline: none;
}

.submit-button:hover {
  background: #cf3d3d;
  cursor: pointer;
}

.contact-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin: 20px auto 0;
}

.contact-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}

.contact-link:hover {
  background-color: rgb(37, 37, 37);
}

.contact-link a {
  text-decoration: none;
}

.contact-link-icon {
  font-size: 4rem;
}

@media screen and (max-width: 960px) {
  .container {
    margin: 50px auto;
    padding: 2rem 1rem;
    max-width: 90%;
  }

  .section-header {
    margin-bottom: 20px;
  }

  .resume-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .resume-aside {
    text-align: left;
    margin-bottom: 0;
  }

  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.9rem;
  }

  p,
  .resume-list,
  .reference-list,
  .education-list {
    font-size: 0.8rem;
  }

  .container {
    max-width: 100%;
    margin: 2rem 1rem;
    border-radius: 0;
  }

  .section-header {
    font-size: 1.75rem;
  }
  .card-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  /* .contact-link {
    text-align: center;
  }

  .contact-links {
    flex-direction: column;
    justify-content: space-around;
  } */

  .contact-link-icon {
    font-size: 2rem;
  }
}
